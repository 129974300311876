import React from 'react';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import TableContainer from '@material-ui/core/TableContainer';
import Chip from '@material-ui/core/Chip';
import Row from './SummaryListItem';
import constants from './../constants';
import './Summary.css';
import {connect} from 'react-redux';

class Summary extends React.Component {
    getData() {
        const {deductions, profile} = this.props.data;
        const {labay, bangka} = deductions.totals;
        const data = [
            {
                name: profile.role === constants.user.roles.ADMINISTRATOR ? 'Receiver 1' : profile.role_description,
                total_straight: labay.total_straight,
                total_ramble: labay.total_ramble,
                details: deductions.labay,
            },
            {
                name: 'Receiver 2',
                total_straight: bangka.total_straight,
                total_ramble: bangka.total_ramble,
                details: deductions.bangka,
            },
        ];

        if (profile.role !== constants.user.roles.ADMINISTRATOR) {
            delete data[1];
        }

        return data;
    }

    render() {
        const {deductions, profile} = this.props.data;
        const {labay, bangka} = deductions.totals;
        const totalGross = parseInt(labay.total) + parseInt(bangka.total);
        const rows = this.getData();
        return (
            <React.Fragment>
                <TableContainer className="mb-5">
                    {deductions.exempted.length > 0 && 
                        <div className="pl-3">
                            <Toolbar disableGutters>
                                <Typography variant="h6" id="tableTitle" component="div">
                                    Exempted
                                </Typography>
                            </Toolbar>
                            <div>
                                {deductions.exempted.map((number, key) => {
                                    return (<Chip key={key} label={number} variant="outlined" className="my-1 mr-1"/>);
                                })}
                            </div>
                        </div>
                    }
                    <Table id="table-summary" aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}/>
                                <TableCell className="font-weight-bold" align="right">Straight</TableCell>
                                <TableCell className="font-weight-bold" align="right">Ramble</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <Row key={index} {...row}/>
                            ))}
                            <TableRow>
                                <TableCell rowSpan={3} colSpan={2}/>
                                <TableCell className="font-weight-bold" align="right">Total Gross</TableCell>
                                <TableCell align="right">{totalGross}</TableCell>
                            </TableRow>
                            {profile.role === constants.user.roles.ADMINISTRATOR &&
                                <React.Fragment>
                                    <TableRow>
                                        <TableCell className="font-weight-bold" align="right">Receiver 1</TableCell>
                                        <TableCell align="right">{labay.total}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="font-weight-bold" align="right">Receiver 2</TableCell>
                                        <TableCell align="right">{bangka.total}</TableCell>
                                    </TableRow>
                                </React.Fragment>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        deductions: state.deductions,
        profile: state.profile,
    }
});

export default connect(mapStateToProps)(Summary);