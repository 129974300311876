module.exports = {
    login: '/login',
    register: '/register',
    home: '/home',
    transactions: '/transactions',
    users: '/users',
    userDetails: '/users/:id',
    createUser: '/users/create',
    editUser: '/users/:id/edit',
    api: {
        login: '/api/login',
        register: '/api/register',
        validateToken: '/api/validate-token',
        profile: '/api/profile',
        schedules: '/api/schedules',
        draws: '/api/draws',
        updateDraw: '/api/draws/:id',
        deductions: '/api/deductions',
        summary: '/api/summary/:id',
        calculatedBets: '/api/calculated-bets/:id',
        transactions: '/api/draws',
        users: '/api/users',
        userDetails: '/api/users/:id',
        userRoles: '/api/user-roles',
        logout: '/api/logout',
    },
};