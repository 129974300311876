module.exports = {
    user: {
        roles: {
            ADMINISTRATOR: 1,
            COORDINATOR: 2,
            RECEIVER: 3,
        },
        presences: {
            ONLINE: true,
            OFFLINE: false,
        }
    },
    keyCodes: {
        TAB: 9,
        ENTER: 13,
    },
    messages: {
        SOMETHING_WENT_WRONG: {
            title: 'Sorry! something went wrong.',
            message: "We're working on it and we'll get it fixed as soon as we can.",
        },
        NOT_FOUND: {
            title: 'Error 404 (Not Found)',
            message: 'The requested URL :url was not found on this server.',
        },
        METHOD_NOT_ALLOWED: {
            title: 'Error 405 (Method Not Allowed)',
            message: 'The request method :method is inappropriate for the URL :url.',
        },
        UNAUTHORIZED: {
            title: 'Error 401 (Unauthorized)',
            message: 'Unauthenticated.',
        },
    },
    components: {
        ALERT: '@material-ui/lab/Alert',
        DIALOG: '@material-ui/core/Dialog',
        SNACKBAR: '@material-ui/core/Snackbar',
    },
    channels: {
        PRIVATE_AUTH: 'stl-private-auth-channel',
    },
    events: {
        LOGIN: 'login-event',
        LOGOUT: 'logout-event',
    },
    socketio: {
        URL: 'http://veranz.net:3000',
    },
};
