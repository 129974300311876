import {combineReducers} from 'redux';

import bets from './bets';
import schedule from './schedule';
import errors from './errors';
import calculatedBets from './calculatedBets';
import straight from './straight';
import ramble from './ramble';
import draw from './draw';
import deductions from './deductions';
import exempted from './exempted';
import profile from './profile';
import flashData from './flashData';

export default combineReducers({
    bets,
    schedule,
    errors,
    calculatedBets,
    straight,
    ramble,
    draw,
    deductions,
    exempted,
    profile,
    flashData,
});