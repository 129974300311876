import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import './Bets.css';
import axios from 'axios';
import routes from './../../src/routes';
import {connect} from 'react-redux';
import {schedule, bets} from './../actions';

class Bets extends React.Component {
    constructor(props) {
        super(props);

        const schedules = localStorage.getItem('schedules');
        const {schedule, bets} = props.data;

        this.state = {
            schedules: schedules === null ? [] : JSON.parse(schedules),
            schedule,
            bets,
        };

        this.handleOnChangeSchedule = this.handleOnChangeSchedule.bind(this);
        this.handleOnChangeBets = this.handleOnChangeBets.bind(this);
    }

    handleOnChangeSchedule(event) {
        this.setState({schedule: event.target.value});
        this.props.schedule(event.target.value);
    }

    handleOnChangeBets(event) {
        this.cursorPosition = event.target.selectionStart;
        const bets = this.autoCorrect(event.target.value);

        window.requestAnimationFrame(() => {
            event.target.selectionStart = this.cursorPosition;
            event.target.selectionEnd = this.cursorPosition;
        });

        this.setState({bets});
        this.props.bets(bets);
    }

    autoCorrect(bets) {
        const lines = bets.toLowerCase().split(/\r\n|\r|\n/);

        return lines.map(line => {
            let replaced = line.replace(/\s/g, '');
            const index = replaced.indexOf('r');

            if (replaced.search(/(?:-|\.)(?:r|R|)/) < 0 && index > 0 && index < 4) {
                replaced = replaced.replace('r', '-r');
                this.cursorPosition += 1;
            }

            return replaced;
        }).join('\n');
    }

    componentDidMount() {
        axios.get(routes.api.schedules, {
            params: {
                optgroup: 1,
            },
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => {
            const {success, data: schedules} = response.data;

            if (success === false) {
                return;
            }

            if (JSON.stringify(this.state.schedules) !== JSON.stringify(schedules)) {
                this.setState({schedules});
                localStorage.setItem('schedules', JSON.stringify(schedules));
            }
        });
    }

    render() {
        const {schedules} = this.state;
        const {errors, draw} = this.props.data;
        return (
            <React.Fragment>
                <FormControl id="form-control-schedule" variant="outlined" className="mb-3" error={'schedule' in errors}>
                    <InputLabel id="label-schedule">Schedule</InputLabel>
                    <Select labelId="label-schedule" id="schedule" label="Schedule" defaultValue=""
                        value={this.state.schedule} onChange={this.handleOnChangeSchedule} disabled={draw !== ''}>
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {schedules.map((schedule, key) => {
                            if ('optgroup' in schedule && schedule.optgroup) {
                                return (
                                    <ListSubheader key={key} value="">
                                        {schedule.label}
                                    </ListSubheader>
                                );
                            }

                            return (
                                <MenuItem key={key} value={schedule.id}>
                                    {schedule.time}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {'schedule' in errors && <FormHelperText className="ml-0">{errors.schedule}</FormHelperText>}
                </FormControl>
                <FormControl className="d-block mb-3" error={'bets' in errors}>
                    <TextField id="bets" label="Bets" multiline rows={22} fullWidth value={this.state.bets}
                        variant="outlined" onChange={this.handleOnChangeBets} error={'bets' in errors}/>
                    {'bets' in errors && <FormHelperText dangerouslySetInnerHTML={{__html: errors.bets}}/>}
                </FormControl>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        schedule: state.schedule,
        bets: state.bets,
        errors: state.errors,
        draw: state.draw,
    }
});

const mapDispatchToProps = {
    schedule,
    bets,
};

export default connect(mapStateToProps, mapDispatchToProps)(Bets);