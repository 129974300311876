import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Avatar from '@material-ui/core/Avatar';
import StyledBadge from './StyledBadge';
import Divider from '@material-ui/core/Divider';
import {Link} from 'react-router-dom';
import axios from 'axios';
import constants from './../constants';
import routes from './../routes';
import helper from '../helper';
import {connect} from 'react-redux';
import {profile, flashData} from './../actions';
import './Header.css';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.anchorRef = React.createRef();

        this.handleToggle = this.handleToggle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleListKeyDown = this.handleListKeyDown.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleToggle(event) {
        this.setState({open: !this.state.open});
    }

    logoutResponse(response) {
        const {success} = response.data;
        if (success) {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = routes.login;
        }
    }

    handleClose(event) {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }
        this.setState({open: false});
    }

    handleListKeyDown(event) {
        if (event.keyCode === constants.keyCodes.TAB) {
            event.preventDefault();
            this.setState({open: false});
        }
    }

    handleLogout(event) {
        this.handleClose(event);
        this.props.profile({});

        axios.post(routes.api.logout, null, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => this.logoutResponse(response))
        .catch(error => helper.handleHttpError(error, this.props));
    }

    render() {
        const {open} = this.state;
        const {profile} = this.props.data;
        return (
            <AppBar position="sticky">
                <Toolbar>
                    <Typography variant="h6" className="mr-auto">
                        <Link className="text-decoration-none text-reset" to={routes.home}>
                            Veranz
                        </Link>
                    </Typography>
                    <div className="d-flex" ref={this.anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={this.handleToggle}>
                        <StyledBadge overlap="circle" variant="dot" anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                            <Avatar alt={profile.name} src={profile.image}/>
                        </StyledBadge>
                        <div className="ml-2">
                            <h6 id="appbar-profile-name" className="m-0">{profile.name}</h6>
                            <small>{profile.role_description}</small>
                        </div>
                    </div>
                    <Popper open={open} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
                        {({TransitionProps, placement}) => (
                            <Grow {...TransitionProps} style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
                                <Paper>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <MenuList id="menu-list-grow" onKeyDown={this.handleListKeyDown} className="py-0">
                                            {profile.role === constants.user.roles.ADMINISTRATOR &&
                                                <MenuItem disableGutters onClick={this.handleClose}>
                                                    <Link className="text-decoration-none text-reset w-100 px-3" to={routes.users}>
                                                        Users
                                                    </Link>
                                                </MenuItem>
                                            }
                                            <MenuItem disableGutters onClick={this.handleClose}>
                                                <Link className="text-decoration-none text-reset w-100 px-3" to={routes.transactions}>
                                                    Transactions
                                                </Link>
                                            </MenuItem>
                                            <Divider/>
                                            <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Toolbar>
            </AppBar>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        profile: state.profile,
        flashData: state.flashData,
    },
});

const mapDispatchToProps = {
    profile,
    flashData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);