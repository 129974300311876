import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import routes from './../routes';
import constants from './../constants';
import helper from '../helper';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {flashData} from './../actions';

const styles = theme => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

class TransactionListItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            downloadProgress: false,
        };

        this.handleToggle = this.handleToggle.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
    }

    handleToggle(event) {
        this.setState({open: !this.state.open});
    }

    downloadFile(url) {
        helper.downloadFile(url).then(() => this.setState({downloadProgress: false}));
    }

    handleDownload(event, id) {
        const url = routes.api.summary.replace(/:id/ig, id);
        this.setState({downloadProgress: true});

        axios.get(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => {
            const {success, download: url} = response.data;
            if (success === false) {
                return;
            }
            this.downloadFile(url);
        })
        .catch(error => {
            helper.handleHttpError(error, this.props);
            this.setState({downloadProgress: false});
        });
    }

    render() {
        const {open, downloadProgress} = this.state;
        const {id, schedule, user, created_at, exempted, summary, classes} = this.props;
        const {labay, bangka} = summary.totals;
        const totalGross = parseInt(labay.total) + parseInt(bangka.total);
        return (
            <React.Fragment>
                <TableRow className={classes.root}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={this.handleToggle}>
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">{schedule.time}</TableCell>
                    <TableCell className="px-0">{user.name}</TableCell>
                    <TableCell align="right">{totalGross}</TableCell>
                    <TableCell align="center">{created_at}</TableCell>
                    <TableCell align="center">
                        <div className="position-relative">
                            <IconButton onClick={event => this.handleDownload(event, id)} disabled={downloadProgress}>
                                <CloudDownloadIcon/>
                            </IconButton>
                            {downloadProgress && <CircularProgress color="secondary" size={24}
                                className="position-absolute circular-progress-action-btn"/>}
                        </div>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="py-0" colSpan={6}>
                        <Collapse id="collapse-details" in={open} timeout="auto" unmountOnExit>
                            {exempted.length > 0 && 
                                <React.Fragment>
                                    <Typography className="m-0" variant="h6" gutterBottom component="div">
                                        Exempted
                                    </Typography>
                                    <div className="mb-1">
                                        {exempted.map((value, index) => (
                                            <Chip key={index} label={value.draw_number} variant="outlined" className="my-1 mr-1"/>
                                        ))}
                                    </div>
                                </React.Fragment>
                            }
                            <Typography className="m-0" variant="h6" gutterBottom component="div">
                                {user.role === constants.user.roles.ADMINISTRATOR ? 'Receiver 1' : user.role_description}
                            </Typography>
                            <Box>
                                <Table id="table-details" className={`${user.role === constants.user.roles.ADMINISTRATOR ? 'mb-2' : 'mb-4'}`}
                                    size="small" aria-label="details">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="font-weight-bold">Draw number</TableCell>
                                            <TableCell className="font-weight-bold" align="right">Straight</TableCell>
                                            <TableCell className="font-weight-bold" align="right">Ramble</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {summary.labay.length === 0 ?
                                            <TableRow>
                                                <TableCell colSpan={3}>&nbsp;</TableCell>
                                            </TableRow> :
                                        summary.labay.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell className="pr-0" component="th" scope="row">
                                                    {row.draw_number}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.straight_diff}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.ramble_diff}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                            {user.role === constants.user.roles.ADMINISTRATOR &&
                                <React.Fragment>
                                    <Typography className="m-0" variant="h6" gutterBottom component="div">
                                        Receiver 2
                                    </Typography>
                                    <Box>
                                        <Table id="table-details" className="mb-4" size="small" aria-label="details">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="font-weight-bold">Draw number</TableCell>
                                                    <TableCell className="font-weight-bold" align="right">Straight</TableCell>
                                                    <TableCell className="font-weight-bold" align="right">Ramble</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {summary.bangka.length === 0 ?
                                                    <TableRow>
                                                        <TableCell colSpan={3}>&nbsp;</TableCell>
                                                    </TableRow> :
                                                summary.bangka.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell className="pr-0" component="th" scope="row">
                                                            {row.draw_number}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.straight}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.ramble}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell rowSpan={3} colSpan={1}/>
                                                    <TableCell className="font-weight-bold" align="right">Total Gross</TableCell>
                                                    <TableCell align="right">{totalGross}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="font-weight-bold" align="right">Receiver 1</TableCell>
                                                    <TableCell align="right">{labay.total}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="font-weight-bold" align="right">Receiver 2</TableCell>
                                                    <TableCell align="right">{bangka.total}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </React.Fragment>
                            }
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        flashData: state.flashData,
    }
});

const mapDispatchToProps = {
    flashData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TransactionListItem));