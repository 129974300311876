import React from 'react';

import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import routes from './routes';
import constants from './constants';
import Login from './components/Login';
import Register from './components/Register';
import Header from './components/Header';
import Home from './components/Home';
import Transactions from './components/Transactions';
import Users from './components/Users';
import UserCreate from './components/UserCreate';
import UserEdit from './components/UserEdit';
import UserDetails from './components/UserDetails';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import {connect} from 'react-redux';
import {profile, flashData} from './actions';
import './App.css';

class App extends React.Component {
    constructor(props) {
        super(props);
        const profile = localStorage.getItem('profile');
        props.profile(profile === null ? {} : JSON.parse(profile));
        this.handleClose = this.handleClose.bind(this);
    }

    validateToken() {
        return axios.get(routes.api.validateToken, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        });
    }

    profile() {
        return axios.get(routes.api.profile, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        });
    }

    profileResponse(response) {
        const {data: profile} = response;
        if (localStorage.getItem('profile') !== JSON.stringify(profile)) {
            this.props.profile(profile);
            localStorage.setItem('profile', JSON.stringify(profile));
        }
    }

    componentDidMount() {
        const entries = [routes.login, routes.register];

        if (entries.includes(window.location.pathname)) {
            return;
        }

        const token = sessionStorage.getItem('token');

        Promise.all([
            this.validateToken(), this.profile()
        ])
        .then(response => this.profileResponse(response[1]))
        .catch(function (error) {
            switch (error.response.status) {
                case 401: // Unauthorized
                    if (token !== null) {
                        sessionStorage.removeItem('token');
                        window.location.href = routes.login;
                    }
                    return;
                default: break;
            }
        });
    }

    handleClose(event) {
        const {flashData} = this.props.data;
        const profile = localStorage.getItem('profile');

        delete flashData[constants.components.DIALOG];

        this.props.flashData({...flashData});
        this.props.profile(profile === null ? {} : JSON.parse(profile));
    }

    render() {
        const auth = sessionStorage.getItem('token') ? true : false;
        const {profile, flashData} = this.props.data;
        return (
            <Router>
                {auth ?
                    (Object.entries(profile).length > 0 ? 
                        <React.Fragment>
                            <Header/>
                            <Switch>
                                <Route exact path={routes.home} component={Home}/>
                                <Route exact path={routes.transactions} component={Transactions}/>
                                {profile.role === constants.user.roles.ADMINISTRATOR &&
                                    <Switch>
                                        <Route exact path={routes.users} component={Users}/>
                                        <Route exact path={routes.createUser} component={UserCreate}/>
                                        <Route exact path={routes.editUser} component={UserEdit}/>
                                        <Route exact path={routes.userDetails} component={UserDetails}/>
                                        <Redirect to={routes.home}/>
                                    </Switch>
                                }
                                <Redirect to={routes.home}/>
                            </Switch>
                        </React.Fragment> :
                        <CircularProgress id="circular-progress-onload" className="position-absolute" color="secondary" size={48}/>) :
                    <Switch>
                        <Route exact path={routes.login} component={Login}/>
                        <Route exact path={routes.register} component={Register}/>
                        <Redirect to={routes.login}/>
                    </Switch>
                }
                {(() => {
                    const dialog = flashData[constants.components.DIALOG];
                    const open = dialog === undefined ? false : true;
                    if (open === false) {
                        return;
                    }
                    const {title, message} = dialog;
                    return (
                        <Dialog open={open}>
                            <DialogTitle>{title}</DialogTitle>
                            <DialogContent>
                                <DialogContentText dangerouslySetInnerHTML={{__html: message}}/>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    );
                })()}
            </Router>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        profile: state.profile,
        flashData: state.flashData,
    },
});

const mapDispatchToProps = {
    profile,
    flashData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);