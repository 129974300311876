import React from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Link} from 'react-router-dom';
import routes from './../routes';
import axios from 'axios';
import constants from '../constants';
import helper from '../helper';
import {flashData} from './../actions';
import {connect} from 'react-redux';

class UserEdit extends React.Component {
    constructor(props) {
        super(props);

        const roles = localStorage.getItem('roles');

        this.state = {
            roles: roles === null ? [] : JSON.parse(roles),
            details: {},
            userDetailsProgress: false,
            saveProgress: false,
            errors: {},
        };

        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleOnChangeName = this.handleOnChangeName.bind(this);
        this.handleOnChangeEmail = this.handleOnChangeEmail.bind(this);
        this.handleOnChangePassword = this.handleOnChangePassword.bind(this);
        this.handleOnChangePasswordConfirmation = this.handleOnChangePasswordConfirmation.bind(this);
        this.handleOnChangeRole = this.handleOnChangeRole.bind(this);
        this.handleOnChangeGrantLoginAccess = this.handleOnChangeGrantLoginAccess.bind(this);
        this.handleOnChangeOverridePassword = this.handleOnChangeOverridePassword.bind(this);
    }

    roles() {
        return axios.get(routes.api.userRoles, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        });
    }

    details() {
        const params = this.props.match.params;
        const url = routes.api.userDetails.replace(/:id/ig, params.id);

        return axios.get(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        });
    }

    rolesResponse(response) {
        const {success, data: roles} = response.data;

        if (success === false) {
            return;
        }

        if (JSON.stringify(this.state.roles) !== JSON.stringify(roles)) {
            this.setState({roles});
            localStorage.setItem('roles', JSON.stringify(roles));
        }
    }

    detailsResponse(response) {
        const {data: details} = response.data;

        details.override_password = false;
        details.password = '';
        details.password_confirmation = '';

        this.setState({userDetailsProgress: false, details});
    }

    componentDidMount() {
        this.setState({userDetailsProgress: true});

        Promise.all([
            this.roles(), this.details()
        ])
        .then(response => {
            this.rolesResponse(response[0]);
            this.detailsResponse(response[1]);
        });
    }

    handleOnChangeName(event) {
        const {details} = this.state;
        details.name = event.target.value;
        this.setState({details});
    }

    handleOnChangeEmail(event) {
        const {details} = this.state;
        details.email = event.target.value;
        this.setState({details});
    }

    handleOnChangePassword(event) {
        const {details} = this.state;
        details.password = event.target.value;
        this.setState({details});
    }

    handleOnChangePasswordConfirmation(event) {
        const {details} = this.state;
        details.password_confirmation = event.target.value;
        this.setState({details});
    }

    handleOnChangeRole(event) {
        const {details} = this.state;
        details.role = event.target.value;
        this.setState({details});
    }

    handleOnChangeGrantLoginAccess(event) {
        const {details} = this.state;
        details.grant_login_access = event.target.checked;
        this.setState({details});
    }

    handleOnChangeOverridePassword(event) {
        const {details} = this.state;
        details.override_password = event.target.checked;
        this.setState({details});
    }

    handleOnSubmit(event) {
        event.preventDefault();

        this.setState({saveProgress: true});

        const {
            name, email, password, password_confirmation,
            override_password, role, grant_login_access
        } = this.state.details;

        const params = this.props.match.params;
        const url = routes.api.userDetails.replace(/:id/ig, params.id);

        axios.put(url, {
            name, email, password, password_confirmation,
            override_password, role, grant_login_access
        }, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => {
            const {success, errors, message} = response.data;
            const flashData = {};

            if (success === false) {
                this.setState({errors});
                return;
            }

            flashData[constants.components.SNACKBAR] = {message};

            this.props.flashData(flashData);
            this.props.history.push(routes.users);
        })
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({saveProgress: false}));
    }

    render() {
        const {userDetailsProgress, roles, details, saveProgress, errors} = this.state;
        return (
            <Container className="py-3 py-md-4 px-md-5 w-auto">
                {userDetailsProgress ?
                    <CircularProgress id="circular-progress-onload" className="position-absolute" color="secondary" size={48}/> :
                    <React.Fragment>
                        <Breadcrumbs aria-label="breadcrumb" className="mb-3">
                            <Link className="text-decoration-none" to={routes.home}>
                                Home
                            </Link>
                            <Link className="text-decoration-none" to={routes.users}>
                                Users
                            </Link>
                            <Link className="text-decoration-none text-reset" to="#">
                                {details.name}
                            </Link>
                            <Link className="text-decoration-none text-reset" to="#">
                                Edit
                            </Link>
                        </Breadcrumbs>
                        <div className="d-flex justify-content-center">
                            <Paper id="paper-user-details" className="p-4 w-50" elevation={1}>
                                <form onSubmit={this.handleOnSubmit}>
                                    <h4>Details</h4>
                                    <p className="text-secondary mb-4">
                                        Fill in the fields below to edit existing user
                                    </p>
                                    <FormControl className="w-100 mb-4" error={'name' in errors}>
                                        <TextField label="Name" variant="outlined" error={'name' in errors}
                                            value={details.name} onChange={this.handleOnChangeName}/>
                                        {'name' in errors && <FormHelperText>{errors.name[0]}</FormHelperText>}
                                    </FormControl>
                                    <FormControl className="w-100 mb-4" error={'email' in errors}>
                                        <TextField label="Email address" variant="outlined" error={'email' in errors}
                                            value={details.email} onChange={this.handleOnChangeEmail}/>
                                        {'email' in errors && <FormHelperText>{errors.email[0]}</FormHelperText>}
                                    </FormControl>
                                    <FormControlLabel label="Override password?" control={<Checkbox checked={details.override_password}
                                        onChange={this.handleOnChangeOverridePassword}/>}/>
                                    <FormControl className="w-100 mb-4" error={'password' in errors}>
                                        <TextField label="Password" type="password" variant="outlined" error={'password' in errors}
                                            value={details.password} onChange={this.handleOnChangePassword} disabled={!details.override_password}/>
                                        {'password' in errors && <FormHelperText>{errors.password[0]}</FormHelperText>}
                                    </FormControl>
                                    <FormControl className="w-100 mb-4">
                                        <TextField label="Confirm password" type="password" variant="outlined" disabled={!details.override_password}
                                            value={details.password_confirmation} onChange={this.handleOnChangePasswordConfirmation}/>
                                    </FormControl>
                                    <FormControl variant="outlined" className="w-100 mb-4" error={'role' in errors}>
                                        <InputLabel id="label-role">Role</InputLabel>
                                        <Select labelId="label-role" label="Role" defaultValue="" error={'role' in errors}
                                            value={details.role || ''} onChange={this.handleOnChangeRole}>
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {roles.map((role, key) => {
                                                return (
                                                    <MenuItem key={key} value={role.value}>
                                                        {role.description}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {'role' in errors && <FormHelperText>{errors.role[0]}</FormHelperText>}
                                    </FormControl>
                                    <div id="row-status" className="row">
                                        <FormControlLabel className="col-7" label="Enable login access?"
                                            control={<Checkbox checked={details.grant_login_access}
                                            onChange={this.handleOnChangeGrantLoginAccess}/>}/>
                                    </div>
                                    <div className="d-flex justify-content-end my-2">
                                        <Link className="btn shadow-none px-3 mr-2" to={routes.users} role="button">
                                            Cancel
                                        </Link>
                                        <div className="position-relative">
                                            <Button variant="contained" color="primary" type="submit" disabled={saveProgress}>
                                                Save
                                            </Button>
                                            {saveProgress && <CircularProgress className="position-absolute circular-progress-action-btn" color="secondary" size={24}/>}
                                        </div>
                                    </div>
                                </form>
                            </Paper>
                        </div>
                    </React.Fragment>
                }
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        flashData: state.flashData,
    },
});

const mapDispatchToProps = {
    flashData,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);