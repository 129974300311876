import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import StyledBadge from './StyledBadge';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import routes from './../routes';
import constants from './../constants';

const styles = theme => ({
    popper: {
        zIndex: 1,
    },
});

class UserListItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.anchorRef = React.createRef();

        this.handleToggle = this.handleToggle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleListKeyDown = this.handleListKeyDown.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleToggle(event) {
        this.setState({open: !this.state.open});
    }

    handleClose(event) {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }
        this.setState({open: false});
    }

    handleListKeyDown(event) {
        if (event.keyCode === constants.keyCodes.TAB) {
            event.preventDefault();
            this.setState({open: false});
        }
    }

    handleScroll(event) {
        this.setState({open: false});
    }

    render() {
        const {open} = this.state;
        const {id, name, image, email, role, status, grant_login_access, classes, presence} = this.props;
        return (
            <TableRow>
                <TableCell component="th" scope="row">
                    <div className="d-flex">
                        <StyledBadge overlap="circle" variant="dot" invisible={!presence}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                            <Avatar alt={name} src={image}/>
                        </StyledBadge>
                        <div className="ml-2">
                            <h6 className="m-0">{name}</h6>
                            <Typography variant="caption">{email}</Typography>
                        </div>
                    </div>
                </TableCell>
                <TableCell>{role}</TableCell>
                <TableCell>
                    <Chip label={status} color={grant_login_access ? 'primary' : 'default'}/>
                </TableCell>
                <TableCell align="center">
                    <IconButton ref={this.anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={this.handleToggle}>
                        <MoreHorizIcon/>
                    </IconButton>
                    <Popper className={classes.popper} open={open} anchorEl={this.anchorRef.current} role={undefined} transition>
                        {({TransitionProps, placement}) => (
                            <Grow {...TransitionProps} style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
                                <Paper>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <MenuList id="menu-list-grow" autoFocusItem={open} onKeyDown={this.handleListKeyDown} className="py-0">
                                            <MenuItem disableGutters onClick={this.handleClose}>
                                                <Link className="text-decoration-none text-reset w-100 px-4" to={routes.userDetails.replace(/:id/ig, id)}>
                                                    View
                                                </Link>
                                            </MenuItem>
                                            <MenuItem disableGutters onClick={this.handleClose}>
                                                <Link className="text-decoration-none text-reset w-100 px-4" to={routes.editUser.replace(/:id/ig, id)}>
                                                    Edit
                                                </Link>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </TableCell>
            </TableRow>
        );
    }
}

export default withStyles(styles)(UserListItem);