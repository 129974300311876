/**
 * Action types
 */
export const SCHEDULE = 'SCHEDULE';
export const BETS = 'BETS';
export const ERRORS = 'ERRORS';
export const CALCULATED_BETS = 'CALCULATED_BETS';
export const STRAIGHT = 'STRAIGHT';
export const RAMBLE = 'RAMBLE';
export const DRAW = 'DRAW';
export const DEDUCTIONS = 'DEDUCTIONS';
export const EXEMPTED = 'EXEMPTED';
export const FILTER_EXEMPTED = 'FILTER_EXEMPTED';
export const CURRENT_DRAW = 'CURRENT_DRAW';
export const PROFILE = 'PROFILE';
export const FLASH_DATA = 'FLASH_DATA';

/**
 * Action creators
 */
 export function schedule(schedule) {
    return {type: SCHEDULE, schedule};
}

export function bets(bets) {
    return {type: BETS, bets};
}

export function errors(errors) {
    return {type: ERRORS, errors};
}

export function calculatedBets(calculatedBets) {
    return {type: CALCULATED_BETS, calculatedBets};
}

export function straight(straight) {
    return {type: STRAIGHT, straight};
}

export function ramble(ramble) {
    return {type: RAMBLE, ramble};
}

export function draw(draw) {
    return {type: DRAW, draw};
}

export function deductions(deductions) {
    return {type: DEDUCTIONS, deductions};
}

export function exempted(exempted) {
    return {type: EXEMPTED, exempted};
}

export function profile(profile) {
    return {type: PROFILE, profile};
}

export function flashData(flashData) {
    return {type: FLASH_DATA, flashData};
}