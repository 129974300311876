import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

class SummaryListItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle(event) {
        this.setState({open: !this.state.open});
    }

    render() {
        const {open} = this.state;
        const {name, total_straight, total_ramble, details, classes} = this.props;
        return (
            <React.Fragment>
                <TableRow className={classes.root}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={this.handleToggle}>
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell className="px-0" component="th" scope="row">
                        {name}
                    </TableCell>
                    <TableCell align="right">{total_straight}</TableCell>
                    <TableCell align="right">{total_ramble}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="py-0" colSpan={4}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Typography className="m-0" variant="h6" gutterBottom component="div">
                                Details
                            </Typography>
                            <Box>
                                <Table id="table-details" className="mb-4" size="small" aria-label="details">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="font-weight-bold">Draw number</TableCell>
                                            <TableCell className="font-weight-bold" align="right">Straight</TableCell>
                                            <TableCell className="font-weight-bold" align="right">Ramble</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {details.length === 0 ?
                                            <TableRow>
                                                <TableCell colSpan={3}>&nbsp;</TableCell>
                                            </TableRow>
                                        : details.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell className="pr-0" component="th" scope="row">
                                                    {row.draw_number}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.straight_diff || row.straight}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.ramble_diff || row.ramble}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(SummaryListItem);