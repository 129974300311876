import React from 'react';

import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TableContainer from '@material-ui/core/TableContainer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import './Deductions.css';
import {connect} from 'react-redux';
import {straight, ramble, exempted} from './../actions';

class Deductions extends React.Component {
    constructor(props) {
        super(props);

        const {straight, ramble, exempted} = props.data;

        this.state = {
            straight,
            ramble,
            exempted,
            openCalculatedBets: false,
        };

        this.handleOnChangeStraight = this.handleOnChangeStraight.bind(this);
        this.handleOnChangeRamble = this.handleOnChangeRamble.bind(this);
        this.handleOnChangeExempted = this.handleOnChangeExempted.bind(this);
        this.handleOnClickCalculatedBets = this.handleOnClickCalculatedBets.bind(this);

        this.transition = React.forwardRef((props, ref) => {
            return <Slide direction="up" ref={ref} {...props}/>;
        });
    }

    handleOnChangeStraight(event) {
        this.setState({straight: event.target.value});
        this.props.straight(event.target.value);
    }

    handleOnChangeRamble(event) {
        this.setState({ramble: event.target.value});
        this.props.ramble(event.target.value);
    }

    handleOnChangeExempted(event) {
        this.setState({exempted: event.target.value});
        this.props.exempted(event.target.value);
    }

    handleOnClickCalculatedBets() {
        this.setState({openCalculatedBets: !this.state.openCalculatedBets});
    }

    render() {
        const {data} = this.props;
        return (
            <React.Fragment>
                <div>
                    <FormControl className="deductions-form-control mr-3 mb-3" error={'straight' in data.errors}>
                        <TextField label="Straight" variant="outlined" value={this.state.straight}
                            onChange={this.handleOnChangeStraight} error={'straight' in data.errors}/>
                        {'straight' in data.errors && <FormHelperText>{data.errors.straight}</FormHelperText>}
                    </FormControl>
                    <FormControl className="deductions-form-control mb-3" error={'ramble' in data.errors}>
                        <TextField label="Ramble" variant="outlined" value={this.state.ramble}
                            onChange={this.handleOnChangeRamble} error={'ramble' in data.errors}/>
                        {'ramble' in data.errors && <FormHelperText>{data.errors.ramble}</FormHelperText>}
                    </FormControl>
                    <FormControl className="d-block mb-3" error={'exempted' in data.errors}>
                        <TextField id="exempted" label="Exempted" multiline rows={16} fullWidth value={this.state.exempted}
                            variant="outlined" onChange={this.handleOnChangeExempted} error={'exempted' in data.errors}/>
                        {'exempted' in data.errors && <FormHelperText>{data.errors.exempted}</FormHelperText>}
                    </FormControl>
                </div>
                <Button className="mb-3" color="primary" onClick={this.handleOnClickCalculatedBets}>
                    Calculated Bets
                </Button>
                <Dialog id="dialog-calculated-bets" fullScreen open={this.state.openCalculatedBets} onClose={this.handleOnClickCalculatedBets} 
                    TransitionComponent={this.transition}>
                    <AppBar className="position-relative">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleOnClickCalculatedBets} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className="col">
                                Calculated Bets
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Container id="container-calculated-bets" className="mt-4">
                        <TableContainer className="mb-4" component={Paper}>
                            <Table id="table-deductions">
                                <caption>Showing {data.calculatedBets.length} entries</caption>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="font-weight-bold">Draw number</TableCell>
                                        <TableCell className="font-weight-bold" align="right">Straight</TableCell>
                                        <TableCell className="font-weight-bold" align="right">Ramble</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.calculatedBets.map((item, key) => {
                                        const {draw_number, straight, ramble} = item;
                                        return (
                                            <TableRow key={key}>
                                                <TableCell component="th" scope="row">
                                                    {draw_number}
                                                </TableCell>
                                                <TableCell align="right">{straight}</TableCell>
                                                <TableCell align="right">{ramble}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        calculatedBets: state.calculatedBets,
        straight: state.straight,
        ramble: state.ramble,
        errors: state.errors,
        exempted: state.exempted,
    },
});

const mapDispatchToProps = {
    straight,
    ramble,
    exempted,
};

export default connect(mapStateToProps, mapDispatchToProps)(Deductions);