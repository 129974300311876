import React from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {Link} from 'react-router-dom';
import routes from './../../src/routes';
import axios from 'axios';
import constants from '../constants';
import helper from '../helper';
import {connect} from 'react-redux';
import {flashData} from './../actions';
import './Login.css';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            loginProgress: false,
            errors: {},
        };

        this.handleOnChangeEmail = this.handleOnChangeEmail.bind(this);
        this.handleOnChangePassword = this.handleOnChangePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleOnChangeEmail(event) {
        this.setState({email: event.target.value});
    }

    handleOnChangePassword(event) {
        this.setState({password: event.target.value});
    }

    loginResponse(response) {
        const {success, token, errors} = response.data;

        if (success === false) {
            this.setState({errors});
            return;
        }

        sessionStorage.setItem('token', token);
        window.location.href = routes.home;
    }

    handleSubmit(event) {
        event.preventDefault();

        sessionStorage.clear();
        localStorage.clear();

        this.setState({
            loginProgress: true
        });

        const {email, password} = this.state;

        axios.post(routes.api.login, { email, password }, {
            headers: {
                Accept: 'application/json',
            }
        })
        .then(response => this.loginResponse(response))
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({loginProgress: false}));
    }

    render() {
        const {email, password, loginProgress, errors} = this.state;
        const {flashData} = this.props.data;
        const alert = flashData[constants.components.ALERT];
        return (
            <React.Fragment>
                <div id="login-hero"></div>
                <Container id="login-container" className="d-grid h-100 px-4" maxWidth="sm">
                    <Paper className={`align-self-${alert === undefined ? 'center' : 'end'}
                        justify-self-center p-4 w-inherit`} elevation={1}>
                        <form onSubmit={this.handleSubmit}>
                            <h4 className="text-center">
                                Sign in
                            </h4>
                            <p className="text-center text-secondary mb-4">
                                Fill in the fields below to sign into your account.
                            </p>
                            <FormControl className="w-100 mb-4" error={'email' in errors}>
                                <TextField label="Email address" variant="outlined" value={email} onChange={this.handleOnChangeEmail} error={'email' in errors}/>
                                {'email' in errors && <FormHelperText>{errors.email[0]}</FormHelperText>}
                            </FormControl>
                            <FormControl className="w-100 mb-4" error={'password' in errors}>
                                <TextField label="Password" type="password" variant="outlined" value={password} onChange={this.handleOnChangePassword} error={'password' in errors}/>
                                {'password' in errors && <FormHelperText>{errors.password[0]}</FormHelperText>}
                            </FormControl>
                            <div className="position-relative mb-4">
                                <Button className="w-100" variant="contained" size="large" color="primary" type="submit" disabled={loginProgress}>
                                    Sign in
                                </Button>
                                {loginProgress && <CircularProgress id="circular-progress-btn-login" className="position-absolute" color="secondary" size={24}/>}
                            </div>
                            <p className="mb-2">
                                Don’t have an account, yet? <Link to={routes.register}>Sign up here</Link>
                            </p>
                        </form>
                    </Paper>
                    {(() => {
                        if (alert === undefined) {
                            return;
                        }
                        const {message} = alert;
                        return (
                            <Paper className="align-self-start p-3 mt-4" elevation={1}>
                                <Alert icon={<CheckCircleOutlineIcon fontSize="inherit"/>} severity="success">
                                    {message}
                                </Alert>
                            </Paper>
                        );
                    })()}
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        flashData: state.flashData,
    },
});

const mapDispatchToProps = {
    flashData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);