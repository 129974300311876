import React from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import routes from './../../src/routes';
import axios from 'axios';
import constants from '../constants';
import helper from '../helper';
import {connect} from 'react-redux';
import {flashData} from './../actions';
import './Login.css';
import './Register.css';

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            registerProgress: false,
            errors: {},
        };

        this.handleOnChangeName = this.handleOnChangeName.bind(this);
        this.handleOnChangeEmail = this.handleOnChangeEmail.bind(this);
        this.handleOnChangePassword = this.handleOnChangePassword.bind(this);
        this.handleOnChangePasswordConfirmation = this.handleOnChangePasswordConfirmation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleOnChangeName(event) {
        this.setState({name: event.target.value});
    }

    handleOnChangeEmail(event) {
        this.setState({email: event.target.value});
    }

    handleOnChangePassword(event) {
        this.setState({password: event.target.value});
    }

    handleOnChangePasswordConfirmation(event) {
        this.setState({password_confirmation: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            registerProgress: true
        });

        const {name, email, password, password_confirmation} = this.state;

        axios.post(routes.api.register, { name, email, password, password_confirmation }, {
            headers: {
                Accept: 'application/json',
            }
        })
        .then(response => {
            const {success, errors} = response.data;
            const flashData = {};

            if (success === false) {
                this.setState({errors});
                return;
            }

            flashData[constants.components.ALERT] = {
                message: 'Your account has been created successfully!',
            };

            this.props.flashData(flashData);
            this.props.history.push(routes.login);
        })
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({registerProgress: false}));
    }

    componentDidMount() {
        this.props.flashData({});
    }

    render() {
        const {name, email, password, password_confirmation, registerProgress, errors} = this.state;
        return (
            <React.Fragment>
                <div id="register-hero"></div>
                <Container id="register-container" className="d-grid h-100 px-4" maxWidth="sm">
                    <Paper className="align-self-center justify-self-center p-4" elevation={1}>
                        <form onSubmit={this.handleSubmit}>
                            <h4 className="text-center">
                                Create account
                            </h4>
                            <p className="text-center text-secondary mb-4">
                                Fill in the fields below to sign up for an account.
                            </p>
                            <FormControl className="w-100 mb-4" error={'name' in errors}>
                                <TextField label="Name" variant="outlined" value={name}
                                    onChange={this.handleOnChangeName} error={'name' in errors}/>
                                {'name' in errors && <FormHelperText>{errors.name[0]}</FormHelperText>}
                            </FormControl>
                            <FormControl className="w-100 mb-4" error={'email' in errors}>
                                <TextField label="Email address" variant="outlined" value={email}
                                    onChange={this.handleOnChangeEmail} error={'email' in errors}/>
                                {'email' in errors && <FormHelperText>{errors.email[0]}</FormHelperText>}
                            </FormControl>
                            <FormControl className="w-100 mb-4" error={'password' in errors}>
                                <TextField label="Password" type="password" variant="outlined" value={password}
                                    onChange={this.handleOnChangePassword} error={'password' in errors}/>
                                {'password' in errors && <FormHelperText>{errors.password[0]}</FormHelperText>}
                            </FormControl>
                            <FormControl className="w-100 mb-4" error={'password' in errors}>
                                <TextField label="Confirm password" type="password" variant="outlined" value={password_confirmation}
                                    onChange={this.handleOnChangePasswordConfirmation} error={'password' in errors}/>
                            </FormControl>
                            <div className="position-relative mb-4">
                                <Button className="w-100" variant="contained" size="large" color="primary"
                                    type="submit" disabled={registerProgress}>
                                    Create your account
                                </Button>
                                {registerProgress && <CircularProgress id="circular-progress-btn-login"
                                    className="position-absolute" color="secondary" size={24}/>}
                            </div>
                            <p className="mb-2">
                                Already have an account? <Link to={routes.login}>Sign in here</Link>
                            </p>
                        </form>
                    </Paper>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        flashData: state.flashData,
    },
});

const mapDispatchToProps = {
    flashData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);