import React from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Link} from 'react-router-dom';
import routes from './../routes';
import axios from 'axios';
import helper from '../helper';
import {connect} from 'react-redux';
import {flashData} from './../actions';

class UserDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            details: {},
            userDetailsProgress: false,
        };
    }

    componentDidMount() {
        const params = this.props.match.params;
        const url = routes.api.userDetails.replace(/:id/ig, params.id);

        this.setState({userDetailsProgress: true});

        return axios.get(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => {
            const {data: details} = response.data;
            this.setState({details});
        })
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({userDetailsProgress: false}));
    }

    render() {
        const {userDetailsProgress, details} = this.state;
        return (
            <Container className="py-3 py-md-4 px-md-5 w-auto">
                {userDetailsProgress ?
                    <CircularProgress id="circular-progress-onload" className="position-absolute" color="secondary" size={48}/> :
                    <React.Fragment>
                        <Breadcrumbs aria-label="breadcrumb" className="mb-3">
                            <Link className="text-decoration-none" to={routes.home}>
                                Home
                            </Link>
                            <Link className="text-decoration-none" to={routes.users}>
                                Users
                            </Link>
                            <Link className="text-decoration-none text-reset" to="#">
                                {details.name}
                            </Link>
                        </Breadcrumbs>
                        <div className="d-flex justify-content-center">
                            <Paper id="paper-user-details" className="p-4 w-50" elevation={1}>
                                <h4 className="mb-4">
                                    Details
                                </h4>
                                <FormControl className="w-100 mb-4">
                                    <TextField label="Name" variant="outlined" disabled defaultValue={details.name}/>
                                </FormControl>
                                <FormControl className="w-100 mb-4">
                                    <TextField label="Email address" variant="outlined" disabled defaultValue={details.email}/>
                                </FormControl>
                                <FormControl variant="outlined" className="w-100 mb-4" disabled>
                                    <InputLabel id="label-role">Role</InputLabel>
                                    <Select labelId="label-role" label="Role" defaultValue="role">
                                        <MenuItem value="role">{details.role_description}</MenuItem>
                                    </Select>
                                </FormControl>
                                <div id="row-status" className="row">
                                    <FormControlLabel className="col-7" disabled label="Enable login access?"
                                        control={<Checkbox checked={details.grant_login_access}/>}/>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Link className="btn shadow-none px-3" to={routes.users} role="button">
                                        Back
                                    </Link>
                                </div>
                            </Paper>
                        </div>
                    </React.Fragment>
                }
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        flashData: state.flashData,
    },
});

const mapDispatchToProps = {
    flashData,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);