import constants from './constants';
import axios from 'axios';

const helper = {
    handleHttpError: (error, props) => {
        const {config, request} = error;
        const {flashData} = props.data;

        const content = {
            ...constants.messages.SOMETHING_WENT_WRONG
        };

        content.message += `<p class='mt-2'><u>${config.method.toUpperCase()}
            ${config.url} ${request.status} (${request.statusText})</u></p>`;

        flashData[constants.components.DIALOG] = {
            ...content,
        };

        props.flashData({...flashData});
    },
    downloadFile: url => {
        const type = 'application/zip';

        return axios.get(url, {
            headers: {
                Accept: type,
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
            responseType: 'arraybuffer'
        })
        .then(response => {
            const {request, data} = response;
            const file = request.responseURL.split('/').pop();
            const blob = new Blob([data], {type});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', file);
            document.body.appendChild(link);
            link.click();
        });
    },
};

export default helper;