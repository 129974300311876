import React from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import {Link} from 'react-router-dom';
import routes from './../routes';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Row from './TransactionListItem';
import './Transactions.css';
import axios from 'axios';
import helper from '../helper';
import {connect} from 'react-redux';
import {flashData} from './../actions';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
});

class Transactions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            transactions: [],
            transactionsProgress: false,
        };

        this.handleTablePaginationActions = this.handleTablePaginationActions.bind(this);
    }

    componentDidMount() {
        this.handleOnChangePage();
    }

    handleOnChangePage(url) {
        this.setState({transactionsProgress: true});

        axios.get(url ?? routes.api.transactions, {
            params: {
                paginate: 1,
                per_page: 10,
            },
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(response => {
            const {success, data} = response.data;

            if (success === false) {
                return;
            }

            this.setState({transactions: data});
        })
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({transactionsProgress: false}));
    }

    handleTablePaginationActions(props) {
        const {transactions} = this.state;
        const {classes, theme} = this.props;
        const {count, page, rowsPerPage} = props;

        const handleOnClickFirstPage = event => this.handleOnChangePage(transactions.first_page_url);
        const handleOnClickBack = event => this.handleOnChangePage(transactions.prev_page_url);
        const handleOnClickNext = event => this.handleOnChangePage(transactions.next_page_url);
        const handleOnClickLastPage = event => this.handleOnChangePage(transactions.last_page_url);

        return (
            <div className={classes.root}>
                <IconButton onClick={handleOnClickFirstPage} disabled={page === 0} aria-label="first page">
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={handleOnClickBack} disabled={page === 0} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton onClick={handleOnClickNext} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton onClick={handleOnClickLastPage} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }

    render() {
        const {transactions, transactionsProgress} = this.state;
        const count = transactions.total ?? 0;
        const rowsPerPage = parseInt(transactions.per_page) ?? 0;
        const page = transactions.current_page ?? 1;
        return (
            <Container className="py-3 py-md-4 px-md-5 w-auto">
                <Breadcrumbs aria-label="breadcrumb" className="mb-3">
                    <Link className="text-decoration-none" to={routes.home}>
                        Home
                    </Link>
                    <Link className="text-decoration-none text-reset" to="#">
                        Transactions
                    </Link>
                </Breadcrumbs>
                {transactionsProgress ?
                    <CircularProgress id="circular-progress-onload" className="position-absolute" color="secondary" size={48}/> :
                    <TableContainer component={Paper} className={`${count === 0 && 'd-none'}`}>
                        <Table id="table-transactions" aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell className="font-weight-bold">Draw</TableCell>
                                    <TableCell className="font-weight-bold">User</TableCell>
                                    <TableCell className="font-weight-bold" align="right">Total</TableCell>
                                    <TableCell className="font-weight-bold" align="center">Created</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {'data' in transactions && transactions.data.map((row, index) => (
                                    <Row key={index} {...row}/>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination rowsPerPageOptions={[]} count={count} rowsPerPage={rowsPerPage} page={page - 1}
                                        onChangePage={this.handleOnChangePage} ActionsComponent={this.handleTablePaginationActions}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                }
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        flashData: state.flashData,
    },
});

const mapDispatchToProps = {
    flashData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(Transactions));