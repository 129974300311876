import React from 'react';

import Container from '@material-ui/core/Container';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Bets from './Bets';
import Deductions from './Deductions';
import Summary from './Summary';
import './Home.css';
import axios from 'axios';
import routes from './../routes';
import constants from './../constants';
import helper from '../helper';
import {connect} from 'react-redux';
import {
    errors,
    calculatedBets,
    draw,
    deductions,
    exempted,
    straight,
    ramble,
    bets,
    flashData,
} from './../actions';

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeStep: 0,
            nextProgress: false,
            skipProgress: false,
            backProgress: false,
            summaryProgress: false,
            downloadProgress: false,
            download: null,
            draw: '',
        };

        this.handleNext = this.handleNext.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleAddBets = this.handleAddBets.bind(this);
        this.handleSummary = this.handleSummary.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
    }

    getSteps() {
        return ['Bets', 'Deductions', 'Summary'];
    }

    isStepDownloadable(step) {
        return [2].includes(step);
    }

    getStepContent(step) {
        switch (step) {
            case 0:
                return <Bets/>;
            case 1:
                return <Deductions/>;
            case 2:
                return <Summary/>;
            default:
                return;
        }
    }

    isStepOptional(step) {
        return [1].includes(step);
    }

    betsResponse(response) {
        const {profile} = this.props.data;
        const {success, data} = response.data;
        const errors = {};

        if (success === false) {
            const {schedule_id, bets} = response.data.errors;

            if (schedule_id !== undefined) {
                errors.schedule = schedule_id[0];
            }

            if (bets !== undefined) {
                errors.bets = bets[0];
            }
        }

        this.props.errors(errors);

        if (Object.entries(errors).length) {
            return;
        }

        this.props.calculatedBets(data.calculated_bets);

        this.setState({draw: data.id});

        if (profile.role === constants.user.roles.ADMINISTRATOR) {
            this.setState({activeStep: 1});
            return;
        }

        this.skipDeductions();
    }

    deductionsResponse(response) {
        const {draw} = this.props.data;
        const {success, data, download} = response.data;
        const errors = {};

        if (success === false) {
            const {straight, ramble, exempted} = response.data.errors;

            if (straight !== undefined) {
                errors.straight = straight[0];
            }

            if (ramble !== undefined) {
                errors.ramble = ramble[0];
            }

            if (exempted !== undefined) {
                errors.exempted = exempted[0];
            }
        }

        this.props.errors(errors);

        if (Object.entries(errors).length) {
            return;
        }

        if (draw === '') {
            this.props.draw(data.draw_id);
        }

        this.props.deductions(data);
        this.setState({download, activeStep: 2});
    }

    cancelBetsResponse(response) {
        if (response.data.success === false) {
            return;
        }

        this.setState({activeStep: 0});
    }

    summaryResponse(response) {
        const {success, data, download} = response.data;

        if (success === false) {
            return;
        }

        this.props.deductions(data);
        this.setState({download, activeStep: 2});
    }

    calculatedBetsResponse(response) {
        const {profile} = this.props.data;
        const {success, data} = response.data;

        if (success === false) {
            return;
        }

        this.props.calculatedBets(data);

        if (profile.role === constants.user.roles.ADMINISTRATOR) {
            this.setState({activeStep: 1});
            return;
        }

        this.cancelBets();
    }

    bets() {
        const {schedule, bets, draw} = this.props.data;
        const params = {bets, schedule_id: schedule};

        if (draw !== '') {
            params.draw_id = draw;
        }

        this.setState({nextProgress: true});

        axios.post(routes.api.draws, params, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => this.betsResponse(response))
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({nextProgress: false}));
    }

    calculatedBets() {
        const {draw} = this.props.data;
        const url = routes.api.calculatedBets.replace(/:id/ig, draw);

        this.setState({backProgress: true});

        axios.get(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => this.calculatedBetsResponse(response))
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({backProgress: false}));
    }

    deductions() {
        const {draw, straight, ramble, exempted} = this.props.data;
        const id = draw || this.state.draw;

        this.setState({nextProgress: true});

        axios.post(routes.api.deductions, { draw_id: id, straight, ramble, exempted }, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => this.deductionsResponse(response))
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({nextProgress: false}));
    }

    skipDeductions() {
        const {draw} = this.props.data;
        const id = draw || this.state.draw;

        this.setState({skipProgress: true});

        this.props.straight('');
        this.props.ramble('');
        this.props.exempted('');

        axios.post(routes.api.deductions, { draw_id: id }, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => this.deductionsResponse(response))
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({skipProgress: false}));
    }

    cancelBets() {
        const {draw} = this.state;
        const url = routes.api.updateDraw.replace(/:id/ig, draw);

        this.setState({backProgress: true});

        axios.put(url, { processed: false }, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => this.cancelBetsResponse(response))
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({backProgress: false}));
    }

    handleNext() {
        switch (this.state.activeStep) {
            case 0: this.bets();
                break;
            case 1: this.deductions();
                break;
            default: break;
        }
    }

    handleBack() {
        this.props.errors({});
        const {activeStep} = this.state;
        switch (activeStep) {
            case 1:
                this.cancelBets();
                break;
            case 2:
                this.calculatedBets();
                break;
            default:
                if (activeStep > 0) {
                    this.setState({activeStep: activeStep - 1});
                }
                break;
        }
    }

    handleSkip() {
        const {activeStep} = this.state;
        switch (activeStep) {
            case 1:
                this.skipDeductions();
                break;
            default:
                this.setState({activeStep: activeStep + 1});
                break;
        }
    }

    handleAddBets() {
        this.props.bets('');
        this.setState({activeStep: 0});
    }

    handleSummary() {
        const {draw} = this.props.data;
        const url = routes.api.summary.replace(/:id/ig, draw);

        this.setState({summaryProgress: true});

        axios.get(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => this.summaryResponse(response))
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({summaryProgress: false}));
    }

    handleDownload() {
        this.setState({downloadProgress: true});
        helper.downloadFile(this.state.download)
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({downloadProgress: false}));
    }

    render() {
        const {activeStep, nextProgress, skipProgress, backProgress, summaryProgress, downloadProgress} = this.state;
        const {draw, profile} = this.props.data;
        const steps = this.getSteps();
        return (
            <Container className="py-3 pt-md-3 pb-md-5">
                <Stepper className="bg-light px-0 px-sm-5" activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                        if (index === 1 && profile.role !== constants.user.roles.ADMINISTRATOR) {
                            return null;
                        }
                        return (
                            <Step key={label}>
                                <StepLabel>
                                    {label} {this.isStepOptional(index) 
                                        && <Typography variant="caption">(Optional)</Typography>}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Paper id="step-content" elevation={1} className="m-auto p-4">
                    {this.getStepContent(activeStep)}
                    <div id="action-buttons" className="d-flex justify-content-between">
                        <div>
                            <div id="action-btn-back" className={`position-relative mr-2 ${activeStep === 0 ? 'd-none' : 'd-inline-flex'}`}>
                                <Button variant="contained" onClick={this.handleBack}
                                    disabled={nextProgress || skipProgress || backProgress || summaryProgress}>
                                    Back
                                </Button>
                                {backProgress && <CircularProgress className="circular-progress-action-btn position-absolute" color="secondary" size={24}/>}
                            </div>
                            <div id="action-btn-skip" className={`position-relative mr-2 ${this.isStepOptional(activeStep) === false ? 'd-none' : 'd-inline-flex'}`}>
                                <Button variant="contained" color="primary" onClick={this.handleSkip}
                                    disabled={nextProgress || skipProgress || backProgress || summaryProgress}>
                                    Skip
                                </Button>
                                {skipProgress && <CircularProgress className="circular-progress-action-btn position-absolute" color="secondary" size={24}/>}
                            </div>
                            <div id="action-btn-next" className={`position-relative mr-2 ${activeStep === steps.length - 1 ? 'd-none' : 'd-inline-flex'}`}>
                                <Button variant="contained" color="primary" onClick={this.handleNext}
                                    disabled={nextProgress || skipProgress || backProgress || summaryProgress}>
                                    Next
                                </Button>
                                {nextProgress && <CircularProgress className="circular-progress-action-btn position-absolute" color="secondary" size={24}/>}
                            </div>
                            <Button id="action-btn-add-bets" className={`${activeStep !== 2 && 'd-none'}`} variant="contained" color="primary"
                                onClick={this.handleAddBets}>
                                Add bets
                            </Button>
                        </div>
                        <div>
                            <div id="action-btn-summary" className={`position-relative ${(activeStep !== 0 || draw === '') ? 'd-none' : 'd-inline-flex'}`}>
                                <Button variant="contained" onClick={this.handleSummary}
                                    disabled={nextProgress || skipProgress || backProgress || summaryProgress}>
                                    Summary
                                </Button>
                                {summaryProgress && <CircularProgress className="circular-progress-action-btn position-absolute" color="secondary" size={24}/>}
                            </div>
                            <div id="action-btn-download" className={`position-relative ${this.isStepDownloadable(activeStep) === false ? 'd-none' : 'd-inline-flex'}`}>
                                <Button variant="contained" onClick={this.handleDownload} disabled={downloadProgress}>
                                    Download
                                </Button>
                                {downloadProgress && <CircularProgress className="circular-progress-action-btn position-absolute" color="secondary" size={24}/>}
                            </div>
                        </div>
                    </div>
                </Paper>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        bets: state.bets,
        schedule: state.schedule,
        straight: state.straight,
        ramble: state.ramble,
        draw: state.draw,
        exempted: state.exempted,
        calculatedBets: state.calculatedBets,
        profile: state.profile,
        flashData: state.flashData,
    },
});

const mapDispatchToProps = {
    errors,
    calculatedBets,
    draw,
    deductions,
    exempted,
    straight,
    ramble,
    bets,
    flashData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);