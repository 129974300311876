import React from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Row from './UserListItem';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Snackbar from '@material-ui/core/Snackbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import constants from './../constants';
import {Link} from 'react-router-dom';
import routes from './../routes';
import axios from 'axios';
import {withStyles} from '@material-ui/core/styles';
import helper from '../helper';
import {connect} from 'react-redux';
import {flashData} from './../actions';
import {io} from "socket.io-client";
import './User.css';

const styles = theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
});

class Users extends React.Component {
    constructor(props) {
        super(props);

        const search = {
            name: ''
        };

        this.state = {
            users: [],
            usersProgress: false,
            open: false,
            search,
        };

        this.handleOnChangeSearchName = this.handleOnChangeSearchName.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleTablePaginationActions = this.handleTablePaginationActions.bind(this);
    }

    handleUserPresenceStatus(user, presence) {
        const {users} = this.state;
        const {profile} = this.props.data;
        const index = users.data.findIndex(({id}) => {
            return id === user.id;
        });

        if (index === -1 || user.id === profile.id) {
            return;
        }

        users.data[index] = {
            ...users.data[index],
            presence,
        };

        this.setState({users});
    }

    componentDidMount() {
        const {flashData} = this.props.data;
        const open = flashData[constants.components.SNACKBAR] === undefined ? false : true;
        this.setState({open});
        this.handleOnChangePage();

        const socket = io(constants.socketio.URL, {transports: ['websocket', 'polling']});

        socket.on(`${constants.channels.PRIVATE_AUTH}:${constants.events.LOGIN}`, ({user}) => {
            this.handleUserPresenceStatus(user, constants.user.presences.ONLINE);
        });

        socket.on(`${constants.channels.PRIVATE_AUTH}:${constants.events.LOGOUT}`, ({user}) => {
            this.handleUserPresenceStatus(user, constants.user.presences.OFFLINE);
        });
    }

    handleOnChangeSearchName(event) {
        const {search} = this.state;
        search.name = event.target.value;
        this.setState({search});
    }

    handleOnChangePage(url, params) {
        this.setState({usersProgress: true});

        axios.get(url ?? routes.api.users, {
            params: {
                ...params,
                paginate: 1,
                per_page: 10,
            },
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => {
            const {success, data} = response.data;

            if (success === false) {
                return;
            }

            this.setState({users: data});
        })
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({usersProgress: false}));
    }

    handleTablePaginationActions(props) {
        const {users} = this.state;
        const {classes, theme} = this.props;
        const {count, page, rowsPerPage} = props;

        const handleOnClickFirstPage = event => this.handleOnChangePage(users.first_page_url);
        const handleOnClickBack = event => this.handleOnChangePage(users.prev_page_url);
        const handleOnClickNext = event => this.handleOnChangePage(users.next_page_url);
        const handleOnClickLastPage = event => this.handleOnChangePage(users.last_page_url);

        return (
            <div className={classes.root}>
                <IconButton onClick={handleOnClickFirstPage} disabled={page === 0} aria-label="first page">
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={handleOnClickBack} disabled={page === 0} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton onClick={handleOnClickNext} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton onClick={handleOnClickLastPage} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }

    handleKeyDown(event) {
        if (event.keyCode === constants.keyCodes.ENTER) {
            event.preventDefault();
            this.handleOnChangePage(routes.api.users, this.state.search);
        }
    }

    render() {
        const {search, users, usersProgress, open} = this.state;
        const {flashData} = this.props.data;
        const count = users.total ?? 0;
        const rowsPerPage = parseInt(users.per_page) ?? 0;
        const page = users.current_page ?? 1;
        return (
            <Container className="py-3 py-md-4 px-md-5 w-auto">
                <Breadcrumbs aria-label="breadcrumb" className="mb-3">
                    <Link className="text-decoration-none" to={routes.home}>
                        Home
                    </Link>
                    <Link className="text-decoration-none text-reset" to="#">
                        Users
                    </Link>
                </Breadcrumbs>
                <Paper className="d-flex mb-4">
                    <IconButton>
                        <SearchIcon/>
                    </IconButton>
                    <InputBase className="flex-fill pr-3" value={search.name} onChange={this.handleOnChangeSearchName}
                        onKeyDown={this.handleKeyDown}/>
                </Paper>
                {usersProgress ?
                    <CircularProgress id="circular-progress-onload" className="position-absolute" color="secondary" size={48}/> :
                    <TableContainer component={Paper} className={`${count === 0 && 'd-none'}`}>
                        <Table id="table-users">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="font-weight-bold">Profile</TableCell>
                                    <TableCell className="font-weight-bold">Role</TableCell>
                                    <TableCell className="font-weight-bold">Status</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {'data' in users && users.data.map((row, index) => (
                                    <Row key={index} {...row}/>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination rowsPerPageOptions={[]} count={count} rowsPerPage={rowsPerPage} page={page - 1}
                                        onChangePage={this.handleOnChangePage} ActionsComponent={this.handleTablePaginationActions}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                }
                <Link to={routes.createUser}>
                    <Fab id="fab-add-user" className="position-fixed" color="primary" aria-label="add">
                        <AddIcon />
                    </Fab>
                </Link>
                {(() => {
                    const snackbar = flashData[constants.components.SNACKBAR];
                    if (open === false) {
                        return;
                    }
                    const {message} = snackbar;
                    return (
                        <Snackbar autoHideDuration={3000} open={open} message={message}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} onClose={() => {
                                this.setState({open: false});
                                this.props.flashData({});
                            }
                        }/>
                    );
                })()}
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        flashData: state.flashData,
        profile: state.profile,
    },
});

const mapDispatchToProps = {
    flashData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(Users));