import React from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Link} from 'react-router-dom';
import routes from './../routes';
import axios from 'axios';
import constants from '../constants';
import helper from '../helper';
import {flashData} from './../actions';
import {connect} from 'react-redux';

class UserCreate extends React.Component {
    constructor(props) {
        super(props);

        const roles = localStorage.getItem('roles');

        this.state = {
            roles: roles === null ? [] : JSON.parse(roles),
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            role: '',
            grant_login_access: false,
            saveProgress: false,
            errors: {},
        };

        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleOnChangeName = this.handleOnChangeName.bind(this);
        this.handleOnChangeEmail = this.handleOnChangeEmail.bind(this);
        this.handleOnChangePassword = this.handleOnChangePassword.bind(this);
        this.handleOnChangePasswordConfirmation = this.handleOnChangePasswordConfirmation.bind(this);
        this.handleOnChangeRole = this.handleOnChangeRole.bind(this);
        this.handleOnChangeGrantLoginAccess = this.handleOnChangeGrantLoginAccess.bind(this);
    }

    componentDidMount() {
        axios.get(routes.api.userRoles, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => {
            const {success, data: roles} = response.data;
    
            if (success === false) {
                return;
            }

            if (JSON.stringify(this.state.roles) !== JSON.stringify(roles)) {
                this.setState({roles});
                localStorage.setItem('roles', JSON.stringify(roles));
            }
        });
    }

    handleOnChangeName(event) {
        this.setState({name: event.target.value});
    }

    handleOnChangeEmail(event) {
        this.setState({email: event.target.value});
    }

    handleOnChangePassword(event) {
        this.setState({password: event.target.value});
    }

    handleOnChangePasswordConfirmation(event) {
        this.setState({password_confirmation: event.target.value});
    }

    handleOnChangeRole(event) {
        this.setState({role: event.target.value});
    }

    handleOnChangeGrantLoginAccess(event) {
        this.setState({grant_login_access: event.target.checked});
    }

    handleOnSubmit(event) {
        event.preventDefault();

        this.setState({
            saveProgress: true
        });

        const {
            name, email, password, password_confirmation, role, grant_login_access
        } = this.state;

        axios.post(routes.api.users, {
            name, email, password, password_confirmation, role, grant_login_access
        }, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => {
            const {success, errors, message} = response.data;
            const flashData = {};

            if (success === false) {
                this.setState({errors});
                return;
            }

            flashData[constants.components.SNACKBAR] = {message};

            this.props.flashData(flashData);
            this.props.history.push(routes.users);
        })
        .catch(error => helper.handleHttpError(error, this.props))
        .then(() => this.setState({saveProgress: false}));
    }

    render() {
        const {
            name, email, password, password_confirmation, role,
            grant_login_access, saveProgress, errors, roles
        } = this.state;

        return (
            <Container className="py-3 py-md-4 px-md-5 w-auto">
                <Breadcrumbs aria-label="breadcrumb" className="mb-3">
                    <Link className="text-decoration-none" to={routes.home}>
                        Home
                    </Link>
                    <Link className="text-decoration-none" to={routes.users}>
                        Users
                    </Link>
                    <Link className="text-decoration-none text-reset" to="#">
                        Create
                    </Link>
                </Breadcrumbs>
                <div className="d-flex justify-content-center">
                    <Paper id="paper-user-details" className="p-4 w-50" elevation={1}>
                        <form onSubmit={this.handleOnSubmit}>
                            <h4>Details</h4>
                            <p className="text-secondary mb-4">
                                Fill in the fields below to create new user
                            </p>
                            <FormControl className="w-100 mb-4" error={'name' in errors}>
                                <TextField label="Name" variant="outlined" error={'name' in errors}
                                    value={name} onChange={this.handleOnChangeName}/>
                                {'name' in errors && <FormHelperText>{errors.name[0]}</FormHelperText>}
                            </FormControl>
                            <FormControl className="w-100 mb-4" error={'email' in errors}>
                                <TextField label="Email address" variant="outlined" error={'email' in errors}
                                    value={email} onChange={this.handleOnChangeEmail}/>
                                {'email' in errors && <FormHelperText>{errors.email[0]}</FormHelperText>}
                            </FormControl>
                            <FormControl className="w-100 mb-4" error={'password' in errors}>
                                <TextField label="Password" type="password" variant="outlined" error={'password' in errors}
                                    value={password} onChange={this.handleOnChangePassword}/>
                                {'password' in errors && <FormHelperText>{errors.password[0]}</FormHelperText>}
                            </FormControl>
                            <FormControl className="w-100 mb-4">
                                <TextField label="Confirm password" type="password" variant="outlined"
                                    value={password_confirmation} onChange={this.handleOnChangePasswordConfirmation}/>
                            </FormControl>
                            <FormControl variant="outlined" className="w-100 mb-4" error={'role' in errors}>
                                <InputLabel id="label-role">Role</InputLabel>
                                <Select labelId="label-role" label="Role" defaultValue="" error={'role' in errors}
                                    value={role || ''} onChange={this.handleOnChangeRole}>
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {roles.map((role, key) => {
                                        return (
                                            <MenuItem key={key} value={role.value}>
                                                {role.description}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {'role' in errors && <FormHelperText>{errors.role[0]}</FormHelperText>}
                            </FormControl>
                            <div id="row-status" className="row">
                                <FormControlLabel className="col-7" label="Enable login access?"
                                    control={<Checkbox checked={grant_login_access}
                                    onChange={this.handleOnChangeGrantLoginAccess}/>}/>
                            </div>
                            <div className="d-flex justify-content-end my-2">
                                <Link className="btn shadow-none px-3 mr-2" to={routes.users} role="button">
                                    Cancel
                                </Link>
                                <div className="position-relative">
                                    <Button variant="contained" color="primary" type="submit" disabled={saveProgress}>
                                        Save
                                    </Button>
                                    {saveProgress && <CircularProgress color="secondary" size={24}
                                        className="position-absolute circular-progress-action-btn"/>}
                                </div>
                            </div>
                        </form>
                    </Paper>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    data: {
        flashData: state.flashData,
    },
});

const mapDispatchToProps = {
    flashData,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);